body{
  font-size: 18px;
  width:100vw;
  max-width:100vw;
  overflow-x:hidden;
  font-family: 'Pathway Gothic One', sans-serif;
  letter-spacing: 0.7px;
  background-color:black;
}

h1{
  font-weight: 400;
  font-size: 3.6rem;
}
.nomatch h2{
  font-weight: 400;
  font-size: 36rem;
  margin-top:0;
}

#preload img{
  height:0px;
  width:0px;
}

.overflow-hidden{
  overflow: hidden;
}

.nav{
  height:100px;
  width:100vw;
  position: absolute;
  z-index:1000;
  top:0px;
  padding-top:18px;
  display:flex; 
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  font-family: Arial, Helvetica, sans-serif;
  font-size:0.9rem;
  letter-spacing:normal;
}

h2{
  font-family: 'Pathway Gothic One', sans-serif;
}

.parallax{
  z-index:10;
}

#home .parallax{
  z-index:0;
}

#nav-paths{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sponsor-logos{
  height: 240px;
  background-color: black;
  text-align: center;
  /* margin-top: -50px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-content: center;
}

.logo-container{
  width:180px;
  height:100px;
  margin-left:1vw;
  margin-right:1vw;
}

.sponsor-logos img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
.clearfix {
  overflow: auto;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.footer{
  position: relative;
  width:60%;
  margin-left:auto;
  margin-right:auto;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing:normal;
  background-color:black;
  color:white;
  height:240px;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  border-top: 2px solid #8AA5A7;
}

.footer-into{
  display:flex;
  flex-direction: column;
}

.footer-info a {
  color: #FFFFFF;
}

.section-container{
  height: 830vh;
}

.section-container-ie{
  height: 740vh;
}

#home{
  height:100vh;
  min-height:500px;
  width:100vw;
  max-width: 100vw;
  position: relative;
  
}

#footer-logo{
  height:110px;
  width:110px;
}

.container{
  height:100vh;
}

p{
  color:white;
}


#nav-logo{
  margin-top:2vw;
  margin-left:2vw;
  height:12vh;
  width:12vh;
}

#nav-social{
  padding-right:5%;
  display: flex;
  align-items: center;
}

.nav-link{
  color:white;
  text-decoration: none;
  font-weight:600;
  padding-left:10px;
  padding-right:10px;
}
.additional{
  margin-top: 100px;
  width: 100%;
}

.footlocker-button{
  padding-left:20px;
  padding-right:20px;
  padding-top:6px;
  font-size: 12px;
  padding-bottom:6px;
  margin-left:6px;
  margin-right:6px;
  color:white;
  background-color: #8AA5A7;
  border:2px solid white;
  border-radius: 25px;
  text-decoration: none;
  font-weight:400;
}

.donate-button{
  padding-left:20px;
  padding-right:20px;
  padding-top:8px;
  padding-bottom:8px;
  margin-left:8px;
  margin-right:8px;
  color:white;
  background-color: #8AA5A7;
  border:2px solid white;
  border-radius: 25px;
  text-decoration: none;
  font-weight:600;
}

.donate-button:hover{
  background-color:white;
  color:#8AA5A7;
  border-color:#8AA5A7;
}

.volunteer-button{
  padding-left:20px;
  padding-right:20px;
  padding-top:8px;
  padding-bottom:8px;
  margin-left:8px;
  margin-right:8px;
  color:white;
  background-color: #023464;
  margin-right:5%;
  border:2px solid white;
  border-radius: 25px;
  text-decoration: none;
  font-weight:600;
}

.volunteer-button:hover{
  background-color:white;
  color:#023464;
  border-color:#023464;
}

.off-colour{
  color:#8AA5A7;
}

.social-icon{
  width:24px;
  height:22px;
  margin-left: 10px;
  margin-right:10px;
}

.footer #nav-social{
  margin-left:0;
  display: inline-block;
}

.footer #nav-social img{
  margin-left:0;
}

.footer #nav-facebook{
  width:12px;
}

.seperator{
  position: absolute;
  width:100vw;
  z-index: 900;
}

.seperator img{
  width:100vw;
  max-height:250px;
}

.seperator-one{
  top:-140px;
  z-index: 900;
}

.seperator-two{
  bottom:-100px;
  z-index: 900;
}

.seperator-four{
  bottom:-113vh;
  z-index: 900;
}

.seperator-four{
  bottom:-113vh;
  z-index: 900;
}

.seperator-five{
  top:-20vh;
  z-index: 900;
}

.seperator-about{
  bottom: -430px;
  z-index: 999;
}

.show{
  display:block;
}

.hidden{
  display:none;
}

/*-- Section One --*/

#section-one{
  background-image: url('../src/assets/sky.png');
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index:0;
  height:130vh;
  min-height:500px;
  width:100vw;
  overflow-x: hidden;
}

#chap-title{
  margin-top:0px;
  color:#8AA5A7;
  font-size:3rem;
}

#main-text-container{
  font-size:3.6rem;
  width:100vw;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top:30%;
  z-index:999;
  font-weight: 500;
  color:white;
}

.flex-center{
  width:100vw;
  display: flex;
  position: relative;
  justify-content: center
}

#scroll-icon-section-one{
  width:45px;
  height:45px;
  margin-top:4%;
  cursor: pointer;
}



.scroll-container{
  width:100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index:15;
}

#sandbagsdiv{
  width:100vw;
  height:100vh;
  position: absolute;
  bottom:0;
  z-index:5;
  background-image: url('../src/assets/fence_sandbags.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

#sandbags-text{
  font-size:3.5rem;
  font-weight:200;
  position: relative;
  top:24%;
  width:80%;
  max-width:800px;
  margin-bottom:10px;
  margin-left:auto;
  margin-right:auto;

}

#sandbags-sub-1{
  font-size:3.5rem;
  font-weight:200;
  position: relative;
  width:auto;
  margin:0;
  margin-left:auto;
  margin-right:auto;
}

#sandbags-sub-2{
  font-size:3.5rem;
  font-weight:200;
  position: relative;
  width:auto;
  margin:0;
  margin-left:auto;
  margin-right:auto;
}

#smoke{
  z-index:1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top:16%;
  right:10vw;
  height:50%;
  opacity: 0.9;
  
}

#cloud-1{
  top:15%;
  left:6%;
  opacity:0.4;
  position:absolute;
}

#cloud-2{
  top:15%;
  left:26%;
  opacity:0.4;
  position:absolute;
}

#plane{
  position: absolute;
  width:30%;
  top:38%;
  left:5%;
  z-index:3;
}

/*-- Explainer --*/

#explainer{
  height:40vh;
  background-color:black;
}

/*-- Section Two --*/

#section-two{
  position: relative;
  z-index:0;
  height:300vh;
  width:100vw;
  background-color:black;
  overflow-x:hidden;
}


#big-plane{
  width: 55vw;
  margin-left: 20%;
  margin-right: 30%;
  z-index: 997;
  top: 1vh;
}

#big-plane-parallax {
  position: absolute;
  top: 86.5vh;
  width: 55vw;
  margin-left: 20%;
  margin-right: 30%;
  z-index: 999;
}

.absolute{
  position: absolute;
}


.fade-out{
  animation: fadeOut 1.3s linear;
  opacity: 0;
}

.fade-in{
  animation: fade 1.3s linear;
}

.fade-in-fast{
  animation: fade 0.1s linear;
}

.slide-up{
  animation: slideUp 0.6s linear;
}

.slide-links{
  position: absolute;
  z-index:997;
  right:5vw;
  bottom:40vh;
}

#section-two-scroll-icon{
  width:45px;
  height:45px;
  bottom:15vh;
  cursor: pointer;
  position: absolute;
  z-index:10;
}

.cursor-icon{
  height:2.5vw;
}

.plane-container{
  height:100vh;
  min-height:500px;
}

.container-ie, .container-ie-large{
  left:-300px !important;
  margin-left:50% !important;
  justify-content: inherit !important;
}
.container-ie-large{
  left:-450px !important;
}

.content-ie{
  justify-content: inherit !important;
}

.slide-link{
  width:1.2vw;
  height:1.2vw;
  max-width:25px;
  max-height:25px;
  margin-top: 13px;
  margin-bottom: 13px;
  background-color:white;
  border-radius: 100px;
  cursor: pointer;
  border:2px solid white;
}

.slide-link-active{
  background-color:#8AA5A7;
  width:1.2vw;
  height:1.2vw;
  max-width:25px;
  max-height:25px;
  margin-top: 13px;
  margin-bottom: 13px;
  border-radius: 100px;
  cursor: pointer;
  border:2px solid white;
}

.slide-link:hover{
  background-color:#8AA5A7;
  border:2px solid #8AA5A7;
  cursor: pointer;
}

#slide-one-text{
  color:white;
  display:flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  justify-content: center;
  width:100vw;
  /* z-index:1; */
}

#slide-one-text h2{
  font-size:4.5rem;
  font-weight:200;
}

#slide-one-text p{
  font-size:1.5rem;
  position: relative;
  font-family: 'Lato', sans-serif;
  top:-50px;
  width:80vw;
  margin-left: auto;
  margin-right:auto;
}

#sticker{
  position:absolute;
  left:80vw;
  top:15vh;
}

#slide-two-text{
  color:white;
  display:flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  justify-content: center;
  width:100vw;
}

#slide-two-text h2{
  font-size:4.5rem;
  font-weight:200;
  
}

#slide-two-text p{
  font-size:1.5rem;
  position: relative;
  font-family: 'Lato', sans-serif;
  top:-50px;
  padding:10px;
}

.slide-item{
  display:flex;
  font-size:1.8rem;
  position: absolute;
  align-content: center;
  align-items:center;
  z-index:998;
  cursor: pointer;
}

.slide-item p{
  margin-left:0.8vw;
  font-weight: 750;
  font-size:1.2rem;
  font-family: 'Lato', sans-serif;
}

.slide-item p:hover{
  color:#8AA5A7;
}

.popup-container{
  position: absolute;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  justify-content: center;
  align-content: center;
  width:100vw; 
  height:100vh;
  z-index:999;
}

.popup-text{
  position: fixed;
  top: 30vh;
  bottom: 0;
  background-image: url('../src/assets/paper_style1.png');
  background-size: contain;
  background-repeat: no-repeat;
  width:800px;
  height:800px;
  padding-left:20px;
  padding-right:20px;
  display: flex;
  flex-direction: row;
  justify-content: inherit;
  align-content: center;
}

.modal-close{
  width:20px;
  height:12px;
  padding:8px;
  color:none;
  background-color:white;
  border-radius: 100px;
  position: relative;
  left: 778px;
  top: 2vh;
  cursor: pointer;
  z-index:999;
}

.popup-text-smaller{
  width:650px;
}

.popup-text-smaller .modal-close{
  left:605px;
}

.popup-text-smallest{
  top:30vh;
  width:560px;
}

.popup-text-smallest .modal-close{
 left:500px;
}

.popup-text-no-images{
  width:500px;
  display:block;
}

.popup-text-large{
  width:650px;
}

.popup-text-largest{
  top:15vh;
  width:870px;
}

#rbd-text p{
  width:400px;
}

.popup-text-no-images .modal-close{
    left: 480px;
    top: 2vh;
}

.popup-text-large .modal-close{
  left: 625px;
}

.popup-text-largest .modal-close{
  left: 820px;
}


#project-text .modal-close{
  left:840px;
}

#production-text .modal-close{
  left:540px;
}

#wings-text .modal-close{
  left: 625px;
}

#wings-text h2{
  margin-top:30%;
}

#purpose-text h2{
  margin-top:20%;
}


.popup-text p{
  margin-left:auto;
  margin-right:auto;
  z-index:999;
  color:black;
  width:300px;
  max-height:60vh;
  padding-left:20px;
  padding-right:20px;
  font-size:1rem !important;
  font-family: 'Lato', sans-serif;
}
.overlay{
  width:100vw;
  height:100vh;
  position: absolute;
}

.popup-text li{
  font-size:1rem !important;
  font-family: 'Lato', sans-serif;
}

.popup-text-no-images p{
  width:auto;
}

.popup-text div{
  margin-left:auto;
  margin-right:auto;
  margin-bottom: auto;
  /* height: 27vw; */
  display: flex;
  flex-direction: column;
  max-width:100vw;
}

.popup-container h2{
  padding-left:20px;
  padding-right:20px;
  top:5vh;
  z-index:999;
  color:black;
  font-family: 'Lato', sans-serif;
}

.image-container{
  margin-top:30px;
  display:flex;
  flex-direction: column;
}


.popup-image-one{
  position: relative;
  height:150px;
  top:60px;
}

.popup-image-two{
  position: relative;
  height:150px;
  top:90px;
}
  
.popup-image-richard{
  position: relative;
  height:400px;
  
  top:80px;
}

#wings {
  bottom: -4.5vw;
  right: 26vw;
}

#engine {
  bottom: -12vw;
  left: 41vw;
}
#tail {
    bottom: -8vw;
    left: 38vw;
}

#propeller{
  bottom: -11.5vw;
  left: 57vw;
}

#covering {
  bottom: -16vw;
  left: 30vw;
}

#plans{
  bottom:-1vw;
  left:44vw;
}

#fuselage{
  bottom:-18vw;
  right:43vw;
}

#who{
  left:30vw;
  top:-2vw;
}

#what {
  top: 16vw;
  left: 43vw;
}

#why{
  left:60vw;
  top:-2vw;
}

#hangar{
  top:9vw;
  left:20vw;
}

#project{
  left:68vw;
  top:8vw;
  width:25vw;
}

#origin {
  top: 7vw;
  left: 31vw;
}

#production {
  top: 15vw;
  left: 34vw;
}

#purpose {
    top: 5vw;
    right: 33vw;
}
#rbd {
  top: -1vw;
  right: 45vw;
}

#know{
  top:10vw;
  right:20vw;
}

#specs {
  top: 14vw;
  right: 35vw;
}
#surgeon{
  top:0vw;
  left:25vw;
}

#washing{
  top:-5vw;
  left:45vw;
}

#first{
  top:5vw;
  left:65vw;
}

#gunners{
  top:15vw;
  left:65vw;
}

#pilots{
  top:15vw;
  left:25vw;
}

#pistols{
  top:16vw;
  left:45vw;
}

.mob-container{
  position: relative;
}

#slide-four-text{
  color:white;
  display:flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  justify-content: center;
  width:100vw;
}

#slide-four-text h2{
  font-size:4.5rem;
  font-weight:200;
  margin-bottom:2vh;
  
}

#slide-four-text p{
  font-family: 'Lato', sans-serif;
  font-size:1.2rem;
}


.next-steps{
  font-size: 1.2rem;
  width:60vw;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  align-items: center;
  align-content:center;
  padding:0px;
  position: relative;
}

.next-steps p{
  width:90%;
}

.number-icon{
  height:2.5vw;
  width:2.5vw;
  margin-right:1.5vw;
  min-width:30px;
  min-height:30px;
}

#slide-one-container{
  background-image: url('../src/assets/hangar.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color:black;
  position: relative;
  height:100vh;
}

#slide-two-container{
  background-image: url('../src/assets/blueprints_bkgd.png');
  background-size:cover;
  background-position: center;
  opacity:1;
  position: relative;
}

.slide-overlay{
  background-color:black;
  height:100vh;
  width:100vw;
  position: absolute;
  opacity:0;
  animation: fadeOut 0.5s linear;
}

#slide-four-container{
  background-image: url('../src/assets/warehouse.png');
  background-position: center;
  background-size:cover;
  position: relative;
}

@keyframes fade {
  0% { opacity: 0 }
  30% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fadeOut {
  0% { opacity: 1 }
  50% { opacity: 0.5 }
  100% { opacity: 0 }
}
@keyframes slideUp {
  0%  {top:1000px;}
  80%  {top:200px;}
  100%  {top:0px;}
}

.sticky-container{
  position: relative;
  background-color:black;
}

#slide-three-text{
  color:white;
  display:flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  justify-content: center;
  width:100vw;
}

#slide-three-text h2{
  font-size:4.5rem;
  font-weight:200;
}

#slide-three-container{
  background-image: url('../src/assets/history_bkgd.png');
  background-position: center;
  background-size:cover;
  background-color:black;
  display: flex;
  text-align: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}

#slide-three-text p{
  font-size:1.5rem;
  position: relative;
  font-family: 'Lato', sans-serif;
  top:-50px;
  width:80vw;
  margin-left:auto;
  margin-right:auto;
}

#slide-five-text{
  color:white;
  display:flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  justify-content: center;
  width:100vw;
}

.scroll-icon-container{
  width:100vw;
  position: absolute;
  bottom:1vw;
  justify-content: center;
}

#scroll-icon-slide-five{
  position: relative;
  width:45px;
  height:45px;
  cursor: pointer;
  z-index:901;
}

.pup-hidden{
  position: absolute;
  top:37vh;
  width:50vw;
  height:23vw;
  left:26vw;
  cursor: pointer;
}

.pup-visible{
  position: absolute;
  top:37vh;
  width:71vw;
  height:25vw;
  left:20vw;
  animation: fade 0.5s linear;
}

.pup-div{
  bottom:-20vw;
  width:80vw;
  height:50vh;
  left:3vw;
  cursor: pointer;
}


#slide-five-text h2{
  font-size:4.5rem;
  font-weight:200;
  text-align: center;
}

#slide-five-container{
  background-image: url('../src/assets/hangar.png');
  background-size:cover;
  background-position: center;
  background-color:black;
  /* display:flex; */
  justify-content: center;
  text-align: center;
  position: relative;
}

#slide-five-text p{
  font-size:1.5rem;
  font-family: 'Lato', sans-serif;
  position: relative;
  top:-50px;
  margin-left:auto;
  margin-right:auto;
  width:74vw;
  
}

#whats-next{
  position: absolute;
  top:42vh;
  height:40vh;
  width:14vw;
  z-index:999;
  left:14vw;
  font-weight: 600;
  color:white;

}


#whats-next p{
  font-size:2.5rem;
  margin-top:5vh;
}

#whats-next-info{
  position: absolute;
  top:27vh;
  height:40vh;
  width:400px;
  z-index:999;
  left:14vw;
  font-weight: 600;
  align-content: center;
  text-align: center;

}

#whats-next-info h2{
  font-size:2.5rem;
  margin-top:5vh;
  color:white;
  animation: fade 0.5s linear;
}


.pup-paper p{
  justify-content: left;
  text-align:left;
  padding-left:60px;
  padding-right:30px;
  padding-top:50px;
  top:-2vh;
  background-image: url('../src/assets/paper6.png');
  background-position: fixed;
  background-size: contain;
  background-repeat: no-repeat;
  width:270px;
  height:260px;
  color:black;
  left:-3vw;
  font-size:1.1rem !important;
  font-family: 'Lato', sans-serif;
  font-weight:500;
  animation: fade 0.5s linear;
  margin-left: auto;
  margin-right: auto;
}


.slide-bottom-text{
  position: absolute;
  top:76vh;
  font-size:1.5rem;
  font-family: 'Lato', sans-serif;
  padding:10px;
  width:100vw;
}

/*-- Section Three --*/

#section-three{
  background-image: url('../src/assets/wall.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index:1;
  height:100vh;
  width:100vw;
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  overflow:hidden;
}

.back-to-top{
  position: relative;
  /* bottom:10vh; */
  color:white;
  font-size:2rem;
  width:100vw;
  text-align: center;
  margin-top: 15px;
}

.back-to-top a{
  text-decoration: underline;
  cursor: pointer;
}

.parallax-container{
  width:25vw;
  min-width:200px;
  min-height:500px;
  position: relative;
  margin:0px;
  z-index:1;
}

.parallax{
  min-height:500px;
}

#poster-static{
  width:20vw;
  height:30vw;
  max-width:400px;
  position: absolute;
  margin:0px;
  top:20vh;
  left:36vw;
  background-image: url('../src/assets/poster.png');
  background-size: contain;
  background-repeat:no-repeat;
}

#poster{
  width:20vw;
  height:30vw;
  max-width:400px;
  /* min-width:500px; */
  position: relative;
  margin:0px;
  top:25vw;
  background-image: url('../src/assets/poster.png');
  background-size: contain;
  background-repeat:no-repeat;
}

#section-three{
  min-height:500px;
}

#section-three-header{
  color:white;
  min-width:30vw;
  margin-left:auto;
  margin-right:auto;
  position: relative;
  bottom:18vh;
  max-height:300px;
  text-align: center;
}

#section-three-header h2{
  font-size:4.5rem;
  font-weight:200;
  min-width:20vw;
}


#section-three-text{
  font-family: 'Lato', sans-serif;
  color:white;
  width:30vw;
  min-width:200px;
  font-size: 1.3rem;
  margin-right:auto;
  margin-left:auto;
  position: relative;
  bottom:4vh;
  padding:1vw;
}

#section-three-text a{
  font-size:1rem !important;
  padding-left:50px;
  padding-right:50px;
  margin-right:0% !important;
}

#section-three-text .donate-button{
  padding-left:65px;
  padding-right:65px;
}

#section-three-text p{
 text-align:left;
}


/*------ About Page -------*/


.about-section-one{
  width:100vw;
  background-color:black;
  display:flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index:1;
}

#about-container{
  background:url('../src/assets/sea_and_sky.png');
  background-size:cover;
  background-repeat: no-repeat;
  background-position: top;
  height:100vh;
  width:100vw;
  position: absolute;
  top:-10vh;
  z-index:1;
}

#apss-logo{
  margin-top:2vw;
  margin-left:2vw;
  height:12vh;
  width:12vh;
  margin-left:auto;
  margin-right:auto;
}

.about-section-two{
  padding-top: 50px;
  min-height:9px;
  width:100vw;
  background-color:black;
  display:flex;
  flex-wrap:wrap;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
}

.about-section-three{
  width:100vw;
  min-height:1100px;
  background-color:black;
  display:flex;
  flex-wrap:wrap-reverse;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
}

.about-section-three-two{
  height: 130vh;
  min-height:1400px;
  width:100vw;
  background-color:black;
  display:flex;
  flex-wrap:wrap;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
}

.about-section-three #about-text{
  width:32vw;
  margin-top:5vh;
}

.about-section-three #about-text ul{
  width:35vw;
}


.about-section-four{
  height:50vh;
  padding-top: 40vh;
  background-color:black;
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
  overflow:hidden;
}

.about-section-four h2{
  color:white;
}

.about-section-four .donate-button{
  font-size:1rem;
}
 
#about-headings{
  color:white;
  position: absolute;
  top:30vh;
  z-index:999;
  width:100vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
}

#about-headings h3{
  margin-top:15vh;
  font-size:2.5rem;
}

#about-headings h4{
  font-size:1.8rem;
  margin:0px;
  font-weight:400;
}

#scroll-icon-about{
  width:45px;
  height:45px;
  cursor: pointer;
  z-index:99;
}

#about-headings h1{
  font-size:4.5rem;
}

#about-headings h2{
  font-size:3.5rem;
} 

#about-info{
  color:white;
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap:wrap;
}

#about-text{
  text-align: left;
  padding:0px;
  font-family: 'Lato', sans-serif;
  width:40vw;
  overflow: visible;
}

#about-text-section-four{
  text-align: left;
  font-family: 'Lato', sans-serif;
  padding-left:5vw;
  padding-right:5vw;
}

#about-text ul{
  text-align: left;
  width:50vw;
  min-width:300px;
  padding:10px;
  font-family: 'Lato', sans-serif;
}

.about-image{
  width:400px;
  margin-left: auto;
  margin-right: auto;
}

#about-image-one{
  display:flex;
  flex-direction: column;
  position: relative;
}

#about-image-two{
  display:flex;
  flex-direction: column;
  position: relative;
}

#about-image-three{
  display:flex;
  flex-direction: column;
  position: relative;
}

#about-image-four{
  display:flex;
  flex-direction: column;
  position: relative;
}

#about-image-five{
  display:flex;
  flex-direction: column;
  position: relative;
}

#about-image-six{
  display:flex;
  flex-direction: column;
  position: relative;
}


#about-image-seven{
  display:flex;
  flex-direction: column;
  position: relative;
}

#about-image-eight{
  display:flex;
  flex-direction: column;
  position: relative;
}

#about-image-nine{
  display:flex;
  flex-direction: column;
  position: relative;
}

#about-image-ten{
  display:flex;
  flex-direction: column;
  position: relative;
}


#about-sandbags{
  background:url('../src/assets/wall_and_sandbags.png');
  position: relative;
  top:70vh;
  height:120vh;
  z-index:5;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.about-parallax-container{
  position: relative;
  margin:0px;
  top:100px;
  margin-left:auto;
  margin-right:auto;
}

.about-text-parallax-container{
  margin-top:5vh;
  margin-left:auto;
  margin-right:auto;
}

#plane-one{
  position: absolute;
  top:50vh;
  left:15vh;
  width:20vw;
  z-index:1;
}

#plane-two{
  position: absolute;
  top:105vh;
  right:10vh;
  width:40vw;
  z-index:1;
}

.plane-div{
  position: relative;
  bottom:-45vh;
  z-index:999;
  background-image:url('../src/assets/plane_withshadow.png');
  z-index:1;
}

/*----------- Media Queries -----------*/

/*-- Navigation Bar --*/

@media screen and (max-width:600px){

  .nav{
    font-size:0.8rem !important;
  }

  #nav-social{
    margin-left:14vw !important;
  }

  .social-icon{
    display:none;
  }

  #nav-twitter{
    display:none;
  }
  
  #nav-facebook{
    display:none;
  }

  #plane{
    width: 61%;
  }
}

@media screen and (max-width:350px){

  .nav{
    font-size:0.6rem !important;
  }

  #nav-social{
    margin-left:16vw !important;
  }

  .social-icon{
    display:none;
  }

  #nav-twitter{
    display:none;
  }
  
  #nav-facebook{
    display:none;
  }
}

/*-- General --*/

@media screen and (max-width:600px){
  .scroll-icon{
    display:none;
  }
}

@media screen and (min-width:1251px){
  .logo-container {
    margin-top: 100px;
  }
}

@media screen and (max-width:1250px){
  
  /* .logo-container{
    width:14vw;
    height:100px;
    margin-left:2vw;
    margin-right:2vw;
  }
  
  .logo-container img{
    margin-top: 40px;
    width:80%;
    height:auto;
  } */
}

@media screen and (max-width:900px){
  /* .logo-container img{
    margin-top: 80px;
    width:80%;
    height:auto;
  } */
  .footer{
    margin-top:100px;
  }
}

@media screen and (max-width:500px){
  .back-to-top{
    margin-bottom: 20px;
    position: relative;
  }
  .logo-container{
    width:100%;
  }
  .logo-container img{
    margin-top: 0;
  }
  .logo-container:nth-of-type(2) img{
    width:20%;
  }
  .logo-container:nth-of-type(3) img{
    width:40%;
  }
  .logo-container:nth-of-type(4) img{
    width:50%;
  }
  .logo-container:nth-of-type(5) img{
    width:30%;
  }
  .logo-container:nth-of-type(6) img{
    width:40%;
    margin-top: 20px;
  }
  .footer{
    margin-top:500px;
  }
}



/*-- Section One --*/

@media screen and (max-height:1000px){
  #main-text-container{
    top:22%;
  }

  #sandbags-text{
    font-size:3.5rem !important;
  }
  
  #section-one p{
    font-size:2rem;
  }

  #nav-social{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin-left:7vw;
  }

  .nav-link{
    color:white;
    text-decoration: none;
    font-weight:600;
  }

  .back-to-top {
    /* bottom: 1vh; */
  }

}

/* @media screen and (min-width:900px){
  .sponsor-logos{
    margin-top: 20px;
  }
} */

@media screen and (max-width:900px){
  
  #main-text-container{
    top:35%;
  }

  #sticker{
    display:none;
  }

  #sandbags-text{
    font-size:3rem !important;
  }

  #main-text-container h1{
    font-size:1.3rem;
  }

  /*--- temp fix!!!! ---*/
  .sponsor-logos{
    height:17vh;
    min-height:140px;
    align-content: flex-start;
  }
  
}

@media screen and (max-height:1100px){
  /* p{
    font-size:1.2rem !important;
  } */

  .slide-item p{
    font-size:0.9rem !important;
  }
/* 
  #about-text p{
      font-size:1.3rem !important;
  } */

  #about-text li{
    /* font-size:1.3rem !important; */
}

#about-text-section-four p{
  font-size:1.3rem !important;
}

  #sandbags-text{
    font-size:2.5rem !important;
  }

  .next-steps p{
    font-size:1rem !important;
  }

}

@media screen and (max-width:900px) and (max-height:1000px){
  #section-one p{
    font-size:2rem !important;
  }

  #sandbags-text{
    font-size:1.5rem !important;
  }
}

@media screen and (max-height:700px) and (min-width:800px){
  #main-text-container{
    top:25%;
  }

  #sandbags-text{
    font-size:1.6rem !important;
  }

  #main-text-container h1{
    font-size:1.6rem;
  }
}

@media screen and (max-height:800px) and (max-width:1200px){
  #section-one p{
    font-size:75% !important;
  }
}

@media screen and (max-width:500px){
  #section-one p{
    font-size:1.4rem !important;
  }
  #footer-logo {
    margin-top: 20px;
  }
}

@media screen and (max-height:650px) and (max-width:650px){
  #section-one p{
    font-size:1.5rem !important;
  }
}

@media screen and (max-height:500px) and (max-width:500px){
  #section-one p{
    font-size:1rem !important;
  }
}




/*-- Section Two --*/

@media screen and (max-height:800px) and (min-width:800px){

  .pup-visible{
    bottom:15vh !important ;
    left: 30vw;
  }

  .pup-hidden{
    bottom:20vh !important ;
    left: 30vw;
  }

  .slide-bottom-text{
    top:78vh;
  }

  .pup-paper p{
    width: 200px;
    height: 173px;
    padding: 32px;
    font-size: 0.9rem !important;

  }

}

@media screen and (min-width:700px) and (max-width:1000px){
  .pup-visible{
    left: 30vw;
  }

  .pup-hidden{
    left: 30vw;
  } 

  .slide-bottom-text{
    top:78vh;
  }

  #whats-next-info{
    left:2vw;
    top: 25vh;
  } 

  .pup-paper p{
    width: 200px;
    height: 173px;
    padding: 32px;
    font-size: 0.9rem !important;
  }
}
@media screen and (max-width:500px){
  .about-image {
    width: 400px;
  }

  #about-image-one {
    margin-top: 120px !important;
  }

  #about-image-three {
    margin-top: 120px !important;
  }

  #about-image-seven {
    margin-top: 120px !important;
  }

  .additional {
    margin-top: 30px
  }
}

@media screen and (max-width:800px){
  .about-image {
    width: 350px;
  }
  /* #slide-one-text p{
    font-size:2.5rem;
  } */

  #section-two{
    height:100vh;
  }

  .slide-item p{
    font-size:0.8rem !important;
  }

  .cursor-icon{
    max-width:30px;
    max-height:30px;
  }

  #whats-next-info h2{
    margin-top:10vh;
  }
 
}

@media screen and (max-width:1300px){

  .slide-bottom-text{
    top:78vh;
  }

}

@media screen and (max-width:1100px){
  .pup-visible{
    left:30vw;
  }


#whats-next-info{ 
  left:5vw;
}
}

@media screen and (max-width:750px){

  .about-section-three-two{
    min-height:2000px;
  }

  #about-headings h3{
    display: none;
  }

  #about-headings h4{
    display: none;
  }

  .slide-links{
    display:none;
  }
 
  #big-plane-parallax{
    position: absolute;
    overflow:hidden;
    width:120vw;
    left:-65vw;
  }

  #big-plane{
    position:absolute;
    overflow:hidden;
    width:80vw;
    right:-50vw;
    top:4vh;
  }

  .extra-top{
    top:10vh !important;
  }


.pup-hidden{
  position: absolute;
  bottom: 28vh;
  width: 400px;
  height: 200px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  
}

#whats-next-info h2 {
  margin-top: 10vh;
}

.pup-visible{
  position: absolute;
  bottom:26vh;
  width:140vw;
  height:50vw;
  left:-15vw;
  animation: fade 0.5s linear;
}

#whats-next{
  position: absolute;
  top:35vh;
  height:10vh;
  width:100vw;
  z-index:999;
  left:unset;
  font-weight: 600;
  color:white;
}

#whats-next-info{ 
  left:unset;
  margin-top:5vw;
}

.pup-paper{
  display:none;
}

}

@media screen and (max-width:750px), (max-height:400px){
.popup-container{
  position: absolute;
  display:flex;
  text-align: center;
  height:auto;
  z-index:999;
}

.popup-text{
  position: fixed;
  background-image: url('../src/assets/paper_style1.png');
  background-position: fixed;
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.popup-text div{
  margin-bottom:0px;
  width: 90vw;
}

.popup-image-richard{
  display:none;
}

.popup-text p{
  padding-left:0px;
  padding-right:0px;
  margin-top:5px;
  z-index:999;
  color:black;
  width:300px;
  font-family: 'Lato', sans-serif;
  font-size:0.9rem !important;
}

.popup-text-no-images .modal-close{
  left: 130vw;
  top: -5vh;
} 

.popup-text-smaller{
  height:100vh;
}

.popup-text-smaller .modal-close{
  top:-5vh;
  left:85vw !important;
}


.popup-text-large{
  width:180vw;
 
}

.popup-text-smallest{
  top: 5vh;
  width:100vw;
  font-size:1rem;
}

.popup-text-large .modal-close{
  left: 90vw;
  top: 7vh;
  margin-top: 4%;
 
}

.popup-text-largest .modal-close{
  left: 90vw;
  top:-2vh;
  
}

#project-text .modal-close{
  left: 86vw;
  top:0vh;
}

#tail-text .modal-close{
  left:84vw;
  top:-2vh;
}

#who-text .modal-close{
  left: 84vw;
}

#propeller-text .modal-close{
  left: 84vw;
}

#covering-text .modal-close{
  left:84vw;
}

.popup-text h2{
  margin-top:-5vw;
  margin-bottom:5vw;
  margin-top:0px !important;
}

.popup-container h2{
  padding-left:20px;
  padding-right:20px;
  top:5vh;
  z-index:998;
  color:black;
  font-family: 'Lato', sans-serif;
}

.popup-text-largest{
  top:0px;
  width: 1000px;
}

.image-container{
  margin-top:0px;
  display:flex;
  flex-direction: row;

}

.popup-image-one{
  position: relative;
  height:30vw;
  width:38vw;
  margin-left:auto;
  margin-right:auto;
  top:30px;
}

.popup-image-two{
  position: relative;
  height:30vw;
  width:38vw;
  top:30px;
  margin-left:auto;
  margin-right:auto;
  }

  .modal-close{
    width:20px;
    height:12px;
    padding:8px;
    color:none;
    background-color:white;
    border-radius: 100px;
    position: relative;
    left: 90vw;
    cursor: pointer;
    z-index:999;
  }

  #engine-text{
    background-size: cover !important;
  }

  #rbd-text{
    background-size: cover !important;
  }

  #rbd-text p{
    width:300px;
  }

  #hangar-text{
    background-size: cover !important;
  }

  #hangar-text .modal-close{
    left: 125vw;
    top: -5vh;
  }

  #who-text{
    background-size: cover !important;
  }

  #why-text{
    background-size: cover !important;
  }

  #rbd-text .modal-close{
    left: 85vw;
    top: -5vh;
  }

  #why-text .modal-close{
    left: 86vw;
    top: -5vh;
  }

  #what-text .modal-close{
    left: 86vw;
    top: -5vh;
  }

  #production-text .modal-close{
    left:75vw;
    top:-3vh;
  }

  #origin-text .modal-close{
    left:85vw;
    top:-0vh;
  }

  #specs-text .modal-close{
    left:85vw;
    top:-0vh;
  }

  #surgeon-text .modal-close{
    left:85vw;
    top:-5vh;
  }

  #first-text .modal-close{
    left:85vw;
    top:-5vh;
  }

  #pilots-text .modal-close{
    left:75vw;
    top:-3vh;
  }

  #gunners-text .modal-close{
    left:75vw;
    top:-3vh;
  }

  

}

@media screen and (max-height:500px) and (max-width:2200px){

  .plane-div{
    position: relative;
    /* bottom:-75vh !important; */
    z-index:999;
    background-image:url('../src/assets/plane_withshadow.png');
    z-index:1;
  }

  .popup-container{
    position: absolute;
    display:flex;
    text-align: center;
    height:auto;
    z-index:999;
  }
  
  .popup-text{
    position: fixed;
    background-image: url('../src/assets/paper_style1.png');
    background-position: fixed;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  
  .popup-text div{
    margin-bottom:0px;
    width: 90vw;
  }
  
  .popup-image-richard{
    display:none;
  }
  
  .popup-text p{
    padding-left:0px;
    padding-right:0px;
    margin-top:5px;
    z-index:999;
    color:black;
    width:300px;
    font-family: 'Lato', sans-serif;
    font-size:0.9rem !important;
  }
  
  .popup-text-no-images .modal-close{
    left: 130vw;
    top: -5vh;
  } 
  
  .popup-text-smaller{
    height:100vh;
  }
  
  .popup-text-smaller .modal-close{
    top:-5vh;
    left:85vw !important;
  }
  
  
  .popup-text-large{
    width:180vw;
   
  }
  
  .popup-text-smallest{
    top: 5vh;
    width:100vw;
    font-size:1rem;
  }
  
  .popup-text-large .modal-close{
    left: 90vw;
    top: 7vh;
    margin-top: 4%;
   
  }
  
  .popup-text-largest .modal-close{
    left: 90vw;
    top:-2vh;
    
  }
  
  #project-text .modal-close{
    left: 86vw;
    top:0vh;
  }
  
  #tail-text .modal-close{
    left:84vw;
    top:-2vh;
  }
  
  #who-text .modal-close{
    left: 84vw;
  }
  
  #propeller-text .modal-close{
    left: 84vw;
  }
  
  #covering-text .modal-close{
    left:84vw;
  }
  
  .popup-text h2{
    margin-top:-5vw;
    margin-bottom:5vw;
    margin-top:0px !important;
  }
  
  .popup-container h2{
    padding-left:20px;
    padding-right:20px;
    top:5vh;
    z-index:998;
    color:black;
    font-family: 'Lato', sans-serif;
  }
  
  .popup-text-largest{
    top:0px;
    width: 1000px;
  }
  
  .image-container{
    margin-top:0px;
    display:flex;
    flex-direction: row;
  
  }
  
  .popup-image-one{
    position: relative;
    height:30vw;
    width:38vw;
    margin-left:auto;
    margin-right:auto;
    top:30px;
  }
  
  .popup-image-two{
    position: relative;
    height:30vw;
    width:38vw;
    top:30px;
    margin-left:auto;
    margin-right:auto;
    }
  
    .modal-close{
      width:20px;
      height:12px;
      padding:8px;
      color:none;
      background-color:white;
      border-radius: 100px;
      position: relative;
      left: 90vw;
      cursor: pointer;
      z-index:999;
    }
  
    #engine-text{
      background-size: cover !important;
    }
  
    #rbd-text{
      background-size: cover !important;
    }
  
    #rbd-text p{
      width:90vw;
    }
  
    #hangar-text{
      background-size: cover !important;
    }
  
    #hangar-text .modal-close{
      left: 125vw;
      top: -5vh;
    }
  
    #who-text{
      background-size: cover !important;
    }
  
    #why-text{
      background-size: cover !important;
    }
  
    #rbd-text .modal-close{
      left: 85vw;
      top: -2vh;
    }
  
    #why-text .modal-close{
      left: 86vw;
      top: -5vh;
    }
  
    #what-text .modal-close{
      left: 86vw;
      top: -5vh;
    }
  
    #production-text .modal-close{
      left:75vw;
      top:-3vh;
    }
  
    #origin-text .modal-close{
      left:85vw;
      top:-0vh;
    }
  
    #specs-text .modal-close{
      left:85vw;
      top:-0vh;
    }
  
    #surgeon-text .modal-close{
      left:85vw;
      top:-5vh;
    }
  
    #first-text .modal-close{
      left:85vw;
      top:-5vh;
    }
  
    #pilots-text .modal-close{
      left:75vw;
      top:-3vh;
    }
  
    #gunners-text .modal-close{
      left:75vw;
      top:-3vh;
    }
}


@media screen and (max-height:400px){
  #big-plane{
    bottom:30vh;
  }
} 


/*-- Section Three --*/

@media screen and (max-width:1000px){

  #section-three{
    flex-direction: column;
    height:100vh;
    text-align:center;
    background-color: rgba(135, 133, 127); 
    background-blend-mode: multiply;
  }

  #section-three-header{
    top:2vh;
  }

  #section-three-header h2{
    font-size:3.5rem;
  }

  #section-three-text{
    margin-top:20vh;
  }

  #section-three-text p{
    font-size:1rem;
  }

  #poster{
    width:30vw;
    max-width:200px;
    position: relative;
    top:50vh;
    margin:0px;
  }

  #poster-static{
    display: none;
  }

  .poster-container{
    display: none;
  }

  #section-three-text{
    color:white;
    width:90%;
    margin-left:auto;
    margin-right:auto;
    bottom:25vh;
    padding:15px;
    padding-bottom:20px;
  }

}

/*-- About --*/

@media screen and (max-width:700px){

  .about-parallax-container{
    max-width: 100%;
  }

  #about-text{
    font-size:19px !important;
    width:80vw;
    margin-top:10vh;
  }

  .about-text-parallax-container{
    width:35vw;
    min-width:300px;
    margin-top:0vh;
    margin-left:auto;
    margin-right:auto;
  }
  

  #about-text h2{
    margin-bottom:unset;
    margin-top:5vh;
  }

  #about-text ul li{
    font-size:15px !important;
  }

  #about-text p{
    font-size:15px !important;
  }

  #about-text-section-four p{
    font-size:15px !important;
}


  .about-section-three #about-text{
    width:80vw;
    font-size:19px;
  }

  .about-section-three #about-text ul{
    width:80vw;
  }

  .about-section-three li{
    font-size:15px;
  }

  .about-image{
    top: -180px;
    width:80vw;
    max-width:300px;
    margin-left:auto;
    margin-right:auto;
  }

  .about-section-four{
    padding-top:10vh;
    padding-bottom:10vh;
  }

  .about-parallax-container{
    padding-right:unset;
    padding-left:unset;
  }

  #about-text ul {
    text-align: left;
    width: 80vw;
    min-width: unset;
    padding: 10px;
    font-size: 0.7rem;
    font-family: 'Lato', sans-serif;
}

.about-section-four{
  height:70vh;
}


}



@media screen and (max-height:800px){
  #about-text{
    font-size:1rem;
  }
}



@media screen and (max-height:500px){
  .seperator{
    display:none;
  }

  .pup-paper{
    display:none;
  }

  .next-steps{
    width:90vw;
    padding-left:5vw;
    padding-right:5vw;
    margin-top:5vh;
    justify-content: center;
    text-align:center;
    flex-direction: column;
  
  }
}

@media screen and (max-width:500px){

  .pup-paper{
    display:none;
  }


.pup-hidden{
  position: absolute;
  top:unset;
  bottom: 35vh;
  width: 400px;
  height: 200px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  
}

#whats-next-info h2 {
  margin-top: 10vh;
}

.pup-visible{
  position: absolute;
  top:unset;
  bottom: 35vh;
  width:140vw;
  height:50vw;
  left:-15vw;
  animation: fade 0.5s linear;
}

}

@media screen and (max-height:800px){
  p{
    font-size:1rem !important;
  }

  .scroll-icon-container{
    display:none;
  }

  .number-icon {
    height: 2.5vw !important;
    width: 2.5vw !important;
    margin-right: 1.5vw;
    margin-top: 0.5vw;
  }

}

@media screen and (max-width:800px){
  .about-text{
    font-size: 10px !important;
  }
}


@media screen and (max-width:600px){

  h2{
    font-size:1.3rem !important;
    margin-bottom: 10vh;
  }
  p{
    font-size:13px !important;
  }

  .seperator{
    width:100vw;
    max-height:20px;
    top:-5vh;
  }

  .seperator-five{
    display:block;
    top:unset;
    display:none;
  }

  .seperator-about{
    top:unset;
    bottom: -127px;
    z-index: 1;
  }

  .seperator img{
    width:100vw;
    top:-20vh;
  }


  .plane-container{
    padding-top:10vh !important;
  }

  .slide-one .slide-item p{
    font-size:1.2rem !important;
  }

  what p{
    font-size:0.9rem !important;
  }


  .cursor-icon{
    height:9.5vw;
  }

  /*--- Slide One --*/

  #who{
    left:10vw;
    top:5vw;
    width:80vw;
  }
  
  #what{
    left:10vw;
    top:20vw;
    width:70vw;
  }
  
  #why{
    left:10vw;
    top:35vw;
    width:60vw;
  }

  #hangar{
      top:68vw;
      left:10vw;
    }
  
  #project{
    left:10vw;
    top:50vw;
    width:80vw;
  }

/*-- Slide Two --*/

#wings{
  left:10vw;
  top:37vw;
  width:30vw;
}

#engine{
  left:unset !important;
  right:10vw;
  top:18vw;
}

#tail{
  top:37vw;
  left:unset;
  right:10vw;
}

#propeller{
  left:10vw;
  top:18vw;
  width:10vw;
}

#covering{
  left:unset !important;
  right:10vw;
  top:55vw;
}

#plans{
  left:10vw;
  top:20vw;
  width:10vw;
}

#fuselage{
  left:10vw;
  top:55vw;
  width:10vw;
}


#origin{
  top:25vw;
  left:20vw;
}

#production{
  top:10vw;
  left:20vw;
}

#purpose{
  top:55vw;
  left:20vw;
}

#rbd{
  top:65vw;
  left:20vw;
}

#know{
  top:50vw;
  left:20vw;
}

#specs{
  top:40vw;
  left:20vw;
}


#surgeon{
  top:5vw;
  left:20vw;
}

#washing{
  top:-10vw;
  left:20vw;
}

#first{
  top:20vw;
  left:20vw;
}

#gunners{
  top:35vw;
  left:20vw;
}

#pilots{
  top:50vw;
  left:20vw;
}

#pistols{
  top:65vw;
  left:20vw;
}

}

/*-- Landscape --*/

@media screen and (max-height:300px), (max-width:750px){

 

  h2{
    font-size:1.3rem !important;
    margin-bottom: 10vh;
  }
  p{
    font-size:0.8rem !important;
  }

  .section-container{
    height: 790vh;
  }

  /*-- temp fix!!! -*/

  #big-plane{
    right:-35vw;
    position: absolute;
    left: -65px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0vh;
  }

  .seperator{
    width:100vw;
    display: none;
    top:-5vh;
  }

  .seperator-five{
    display:block;
    top:unset;
    bottom:-545vh;
  }

  .seperator-about{
    top:unset;
    bottom: -127px;
    z-index: 999;
  }

  .seperator img{
    width:100vw;
    /* max-height:20px; */
    top:-20vh;
  }

  .image-container{
    display:none !important;
  }

  .slide-text p{
    /* min-width:75vw !important; */
  }

  .plane-container{
    padding-top:10vh !important;
  }

  .slide-one .slide-item p{
    font-size:1.2rem !important;
  }

  what p{
    font-size:0.9rem !important;
  }

  /*--- Slide One --*/

  #who{
    left:10vw;
    top:8vh;
    width:80vw;
  }
  
  #what{
    left:10vw;
    top:30vh;
    width:70vw;
  }
  
  #why{
    left:10vw;
    top:16vh;
    width:60vw;
  }
  
  #project{
    left:10vw;
    top:22vh;
    width:80vw;
  }

  #hangar{
      left:10vw;
      top:0vh;
      width:70vw;
  }

/*-- Slide Two --*/

#wings{
  left:10vw;
  top:27vh;
  width:30vw;
}

#engine{
  left:unset !important;
  right:10vw;
  top:18vh;
}

#tail{
  top:27vh;
  left:unset;
  right:10vw;
}

#propeller{
  left:10vw;
  top:18vh;
  width:10vw;
}

#covering{
  left:unset !important;
  right:10vw;
  top:35vh;
}

#plans{
  left:10vw;
  top:20vh;
  width:10vw;
}

#fuselage{
  left:10vw;
  top:35vh;
  width:10vw;
}


#origin{
  top:21vh;
  left:20vw;
}

#production{
  top:5vh;
  left:20vw;
}

#purpose{
  top:13vh;
  left:20vw;
}

#know{
  top:25vh;
  left:20vw;
}

#specs{
  top:29vh;
  left:20vw;
}

#rbd{
  top:35vh;
  left:20vw;
}

/*-- Slide Four --*/



/*-- Slide Five --*/


#washing{
  top:-15vh;
  left:20vw;
}

#pistols{
  top:-7vh;
  left:20vw;
}

#first{
  top:1vh;
  left:20vw;
}

#gunners{
  top:8vh;
  left:20vw;
}

#pilots{
  top:16vh;
  left:20vw;
}

#surgeon{
  top:24vh;
  left:20vw;
}

#scroll-icon-slide-five{
  /* bottom:4vw; */
 
}

.slide-bottom-text{
  bottom:30vw;
}

.popup-text p{
  margin-left:auto;
  margin-right:auto;
  z-index:999;
  color:black;
  width:90vw;
  max-height:60vh;
  font-size:0.9rem !important;
  font-family: 'Lato', sans-serif;
}

}

@media screen and (max-height:500px) and (min-width:550px){
  
  p{
    font-size:0.8rem !important;
  }

  /*-- temp fix!!!! --*/
  .footer{
    display:none;
  }
  /*-- temp fix!!! -*/

  .plane-div {
    bottom: -58vh !important;
  }

  #big-plane{
    width:400px;
    position: absolute;
    left: 0;
    right: 0;
    top:5vh;
    margin-left: auto;
    margin-right: auto;
  }

  .seperator{
    width:100vw;
    display: none;
    top:-5vh;
  }

  .seperator-five{
    display:block;
    top:unset;
    bottom:-545vh;
  }

  .seperator-about{
    top:unset;
    bottom: -127px;
    z-index: 999;
  }

  .seperator img{
    width:100vw;
    top:-20vh;
  }

  .image-container{
    display:none !important;
  }

  .plane-container{
    padding-top:10vh !important;
  }

  .slide-one .slide-item p{
    font-size:1.2rem !important;
  }

  what p{
    font-size:0.9rem !important;
  }

  /*--- Slide One --*/

  #who{
    left:10vw;
    top:20px;
    width:80vw;
  }
  
  #what{
    left:10vw;
    top:100px;
    width:70vw;
  }
  
  #why{
    left:10vw;
    top:60px;
    width:60vw;
  }
  
  #project{
    left:10vw;
    top:140px;
    width:80vw;
  }

  #hangar{
      left:10vw;
      top:180px;
      width:70vw;
  }

/*-- Slide Two --*/

#wings{
  left:10vw;
  top:115px;
  width:30vw;
  bottom:unset;
}

#engine{
  left:unset !important;
  right:10vw;
  top:120px;
  bottom:unset;
}

#tail{
  top:62px;
  left:unset;
  right:10vw;
  bottom:unset;
}

#propeller{
  left: 10vw;
    top: 62px;
    width: 10vw;
    bottom: unset;
}

#covering{
  left:unset !important;
  right:10vw;
  top:170px;
  bottom:unset;
}

#plans{
  left:10vw;
  top:20vh;
  width:10vw;
}

#fuselage{
  left: 10vw;
  top: 170px;
  width: 10vw;
  bottom: unset;
}


#origin{
  top:105px;
  left:20vw;
}

#production{
  top:5px;
  left:20vw;
}

#purpose{
  top:55px;
  left:20vw;
}

#know{
  top:85px;
  left:20vw;
}

#specs{
  top:205px;
  left:20vw;
}

#rbd{
  top:145px;
  left:20vw;
}

/*-- Slide Four --*/



/*-- Slide Five --*/

#surgeon{
  top:5px;
  left:20vw;
}

#washing{
  top:55px;
  left:20vw;
}

#first{
  top:105px;
  left:20vw;
}

#gunners{
  top:155px;
  left:20vw;
}

#pilots{
  top:205px;
  left:20vw;
}

#pistols{
  top:255px;
  left:20vw;
}

.slide-bottom-text{
  top:unset;
  bottom:20px;
}

#whats-next-info {
  left: 2vw;
  top: 60vh;
}

#whats-next {
  position: absolute;
  top: 52vh;
}

.pup-visible{
  top: 60vh;
}

.pup-hidden{
  top: 60vh;
}
}

@media (max-width:500px){
  #big-plane{
    right:-35vw;
    position: absolute;
    /* left: -170px; */
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top:-5vh;
    width:100vw;
  }
}

@media (max-width:400px){
  #big-plane{
    right:-35vw;
    position: absolute;
    left: -105px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top:5vh;
    width:130vw;
  }
}

span.line {
  display: inline-block;
}

@media (max-width:1024px ) and (min-width: 752px){

  #nav-logo {
    margin-top: 6vw;
  }

  .popup-text-largest {
    width: 612px;
    font-size: 10px;
  }


.popup-text {
    width: 600px;
  }
  .popup-text .modal-close {
    top: 24px;
    left: 580px;
  }

  #wings-text .modal-close {
    left: 575px;
  }
  .popup-text p {
    font-size: 0.8rem!important;
  }

  #big-plane, #big-plane-parallax{
    width: 83vw;
    margin-left: 5%;
    margin-right: 30%;
  }

  .popup-image-richard {
    height: 207px;
}

  #project-text .modal-close {
    left: 575px;
  }

  #who {
    left: 20vw;
    top: -2vw;
  }

  #why {
    left: 61vw;
    top: -2vw;
  }

  #what {
    top: 29vw;
    left: 38vw;
  }

  #hangar {
    top: 11vw;
    left: 15vw;
  }

  #project {
    left: 66vw;
    top: 8vw;
    width: 25vw;
  }


  #tail {
    bottom: -13vw;
    left: 33vw;
  }

  #propeller {
    bottom: -18.5vw;
    left: 62vw;
  }

  #wings {
    bottom: -8.5vw;
    right: 9vw;
  }

  #fuselage {
    bottom: -26vw;
    right: 34vw;
  }

  #engine {
    bottom: -19vw;
    left: 35vw;
  }

  #covering {
    bottom: -26vw;
    left: 18vw;
  }
  #origin {
    top: 10vw;
    left: 22vw;
  }

  #rbd {
    top: -3vw;
    right: 36vw;
  }

  #purpose {
    top: 10vw;
    right: 21vw;
  }

  #production {
    top: 22vw;
    left: 29vw;
  }

  #specs {
    top: 21vw;
    right: 28vw;
  }

  #surgeon {
    top: 1vw;
    left: 11vw;
  }

  #washing {
    top: -4vw;
    left: 42vw;
  }

  #first {
    top: 6vw;
    left: 65vw;
  }

  #gunners {
    top: 19vw;
    left: 65vw;
  }

  #pistols {
    top: 24vw;
    left: 42vw;
  }

  #pilots {
    top: 18vw;
    left: 20vw;
  }

  #whats-next {
    top: 49vh;
  }
  .pup-hidden {
    top: 50vh;
    width: 66vw;
    height: 32vw;
    left: 29vw;

  }

  .pup-visible {
    top: 50vh;
    width: 80vw;
    height: 32vw;
    left: 29vw;
  }
}
.buttonWrapper {
  width: 500px;
}
@media (max-width:500px ){
  .CookieConsent {
    width: 325px !important;
    margin-left: -162px !important;
  }

}